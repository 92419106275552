import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "datatable-table__td-cell",
    class: {
      'align-right': ['number', 'money', 'percent', 'date'].includes(_vm.h.type) || _vm.h.filter === 'numberRange',
      'no-overflow': _vm.h.noOverflow
    }
  }, [_vm.h.getImage && _vm.h.getImage(_vm.item) ? _c('div', {
    staticClass: "datatable-table__td-title-image"
  }, [_c('img', {
    class: {
      hoverUp: _vm.index > 3
    },
    attrs: {
      "src": _vm.wb7p_image(_vm.h.getImage(_vm.item))
    },
    on: {
      "error": function ($event) {
        $event.target.src = require('@/assets/images/img.svg');
      }
    }
  }), _c('img', {
    staticClass: "image-big",
    class: {
      hoverUp: _vm.index > 3
    },
    attrs: {
      "src": _vm.wb7p_image(_vm.h.getImage(_vm.item))
    },
    on: {
      "error": function ($event) {
        $event.target.src = require('@/assets/images/img.svg');
      }
    }
  })]) : _vm._e(), _c('div', {
    class: ['datatable-table__td-title-text', {
      'add-place': _vm.h.getAdditionalInfo
    }]
  }, [_vm.h.type === 'money' ? [_vm.item[_vm.h.name] === -1 ? _c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")]) : _vm.item['_css_' + _vm.h.name] != '' ? _c('span', {
    class: _vm.item['_css_' + _vm.h.name]
  }, [_vm.h.getAdditionalInfo ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.h.getAdditionalInfo(_vm.item[_vm.h.name], _vm.item))
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.item[_vm.h.name]) + " ₽ ")])]) : _c('span', [_vm.h.getAdditionalInfo ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.h.getAdditionalInfo(_vm.item[_vm.h.name], _vm.item))
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.item[_vm.h.name]) + " ₽ ")])])] : _vm.h.type === 'html' ? [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.item[_vm.h.name])
    }
  })] : _vm.h.type === 'percent' ? [_vm.item[_vm.h.name] === -1 ? _c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")]) : _vm.item[_vm.h.name] === null ? _c('span') : _c('span', [_vm._v(" " + _vm._s(_vm.item[_vm.h.name]) + " % ")])] : _vm.h.type === 'slot' ? [_vm._t(_vm.h.name, null, null, {
    ..._vm.rawResponse,
    item: _vm.item
  })] : _vm.h.type === 'actions' ? [_c('app-select', {
    attrs: {
      "items": _vm.getActionsItems(_vm.h),
      "icon-misc": "",
      "width": "28"
    },
    on: {
      "select": function ($event) {
        return _vm.onActionSelect(_vm.item, $event);
      }
    }
  })] : _vm.h.type === 'link' ? [_vm.h.getLink(_vm.item).text === '&:NA' || _vm.h.getLink(_vm.item).text === '-1' || _vm.h.getLink(_vm.item).text === -1 ? [_c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")])] : ['Ozon', 'ООО ВАЙЛДБЕРРИЗ', 'ВАЙЛДБЕРРИЗ ООО'].includes(_vm.h.getLink(_vm.item).text) ? [_c('span', {
    staticClass: "gray link-text",
    class: {
      'max-width': _vm.item.bordered
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).text)
    }
  }), _vm.h.getLink(_vm.item).slotRight ? _c('span', {
    staticClass: "link-slot-right",
    class: {
      hover: _vm.h.getLink(_vm.item).slotRightHover
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).slotRight)
    }
  }) : _vm._e()] : [_vm.h.getLink(_vm.item).route ? _c('router-link', {
    attrs: {
      "to": _vm.h.getLink(_vm.item).route,
      "title": _vm.h.getLink(_vm.item).text,
      "target": _vm.h.getLink(_vm.item).target
    }
  }, [_vm.h.getAdditionalInfo ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.h.getAdditionalInfo(_vm.item[_vm.h.name], _vm.item))
    }
  }) : _c('span', {
    staticClass: "link-text",
    class: {
      'max-width': _vm.item.bordered
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).text)
    }
  }), _vm.h.getLink(_vm.item).slotRight ? _c('span', {
    staticClass: "link-slot-right",
    class: {
      hover: _vm.h.getLink(_vm.item).slotRightHover
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).slotRight)
    }
  }) : _vm._e()]) : _vm._e(), _vm.h.getLink(_vm.item).href ? _c('a', {
    attrs: {
      "href": _vm.h.getLink(_vm.item).href,
      "title": _vm.h.getLink(_vm.item).text,
      "target": "_blank"
    }
  }, [_vm.h.getAdditionalInfo ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.h.getAdditionalInfo(_vm.item[_vm.h.name], _vm.item))
    }
  }) : _c('span', {
    staticClass: "link-text",
    class: {
      'max-width': _vm.item.bordered
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).text)
    }
  }), _vm.h.getLink(_vm.item).slotRight ? _c('span', {
    staticClass: "link-slot-right",
    class: {
      hover: _vm.h.getLink(_vm.item).slotRightHover
    },
    domProps: {
      "innerHTML": _vm._s(_vm.h.getLink(_vm.item).slotRight)
    }
  }) : _vm._e()]) : _vm._e()]] : _vm.h.type === 'date' ? [_vm.item[_vm.h.name] && _vm.formatDate(_vm.item[_vm.h.name]).slice(0, 10) == '1970-01-01' ? _c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")]) : _vm.item['_css_' + _vm.h.name] != '' ? _c('span', {
    class: _vm.item['_css_' + _vm.h.name]
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item[_vm.h.name])) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item[_vm.h.name])) + " ")])] : _vm.h.type === 'datetime' ? [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.item[_vm.h.name])) + " ")] : _vm.h.type === 'datetimeLocal' ? [_vm._v(" " + _vm._s(_vm.formatDateTimeLocal(_vm.item[_vm.h.name])) + " ")] : [_vm.h.getText ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.h.getText(_vm.item[_vm.h.name], _vm.item))
    }
  }) : _vm.h.values && _vm.h.values[_vm.item[_vm.h.name]] !== undefined ? _c('span', [_vm._v(" " + _vm._s(_vm.h.values[_vm.item[_vm.h.name]]) + " ")]) : _vm.item[_vm.h.name] === -1 ? _c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")]) : _vm.item['_css_' + _vm.h.name] !== '' ? _c('span', {
    class: _vm.item['_css_' + _vm.h.name]
  }, [_vm._v(" " + _vm._s(_vm.item[_vm.h.name]) + " ")]) : _c('span', {
    attrs: {
      "title": _vm.item[_vm.h.name]
    }
  }, [_vm._v(" " + _vm._s(_vm.item[_vm.h.name]) + " ")])]], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };